.form {
  position: relative;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #c1d1d1;
  display: block;
  width: 100%;
  font-size: 1.25rem;
}

.control input:focus,
.control textarea:focus {
  background-color: #cbf8f8;
  outline-color: teal;
}

.actions {
  text-align: right;
}

.actions button {
  font-size: 1.25rem;
}
